<template>
  <div class="my-5">
    <!-- 簡介 -->
    <div id="intro" class="container position-relative text-on-img py-5">
      <!-- only show on large screen -->
      <img
        class="position-absolute w-100 h-100 d-none d-sm-none d-lg-block"
        src="@/assets/images/product/520x_lg.jpg"
      />
      <div class="row justify-content-center">
        <div class="col-lg-7 col-sm-10">
          <!-- only show on middle and small screen -->
          <img class="w-100 d-lg-none" src="@/assets/images/product/520x.jpg" />
        </div>
        <div class="col-lg-4 col-sm-10 mb-5 pb-5 text-start">
          <img class="w-50" src="@/assets/images/logo520x.png" />
          <h3>{{ $t("520x.introTitle") }}</h3>
          <p>{{ $t("520x.intro") }}</p>
          <a
            class="hvr-icon-wobble-horizontal"
            href="../features/OBSERV520x_features.html"
          >
            {{ $t("520x.more") }}
            <i class="fa-solid fa-angles-right hvr-icon"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- 影片 -->
    <div class="container mt-5 pb-5">
      <h3 class="text-start">
        {{ $t("520x.videoTitle")
        }}<span class="text-gray">{{ $t("520x.videoSubtitle") }}</span>
      </h3>
      <iframe
        class="w-100"
        src="https://www.youtube.com/embed/s3GOmZk7vr0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <!-- 光源介紹 -->
    <div class="mt-5 bg-light">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-sm-10">
          <img
            v-if="activeLM == 'dynamic' && indexCN == 0"
            class="ipad"
            src="@/assets/images/product/520x/dynamic_0.gif"
          />
          <img
            v-else
            class="ipad"
            :src="require(`@/assets/images/product/520x/${activeLM}_${indexCN}.png`)"
          />
        </div>
        <div class="col-lg-4 col-sm-10 my-5">
          <div class="d-inline-flex row justify-content-around bg-white rounded-pill p-2 my-3">
            <div
              class="col-1 lm"
              role="button"
              v-for="(lm, index) in lights"
              :key="index"
              :class="{ active: activeLM == lm }"
              @click="toggleLM(lm)"
            >
              <span class="center h3 m-0" :class="`icon-${lm}`"></span>
            </div>
          </div>
          <h3 class="text-start">{{ $t(`520x.${activeLM}`) }}</h3>
          <p class="text-start" v-html="$t(`520x.${activeLM}ds`)"></p>
          <div v-show="$t(`520x.${activeLM}CN`).split(',').length > 1" class="row">
            <div
              role="button"
              class="concern col-lg-4 col-sm-5 hvr-sweep-to-right my-1 py-1"
              v-for="(cn, index) in $t(`520x.${activeLM}CN`).split(',')"
              :key="index"
              :class="{ active: indexCN == index }"
              @click="toggleCN(index)"
            >
              <span>{{ cn }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 創新優異的歐洲品質 -->
    <div class="pt-5 container">
      <div class="row justify-content-around">
        <div class="col-lg-5 col-sm-10 center">
          <div class="text-start">
            <h3>{{ $t("520x.title1") }}</h3>
            <p>{{ $t("520x.description1") }}</p>
          </div>
        </div>
        <div class="col-lg-5 col-sm-10">
          <img class="w-100" src="@/assets/images/product/520x/520x_01.png" />
        </div>
      </div>
    </div>
    <!-- 使用OBSERV進行皮膚分析的五大優勢 -->
    <div class="pt-5 bg-light">
      <div class="container position-relative">
        <img
          class="w-100"
          :src="require(`@/assets/images/product/520x/520x_02_${$i18n.locale}.png`)"
        />
        <h3
          id="title2"
          class="position-absolute ps-5 text-start text-black"
          v-html="$t('520x.title2')"
        ></h3>
      </div>
    </div>
    <!-- OBSERV 520X 幫助您 -->
    <div class="mt-5 mb-5 container">
      <div class="row justify-content-around">
        <div class="col-lg-4 col-sm-10 center">
          <div class="text-start">
            <img class="w-100" src="@/assets/images/product/520x/520x_03.png" />
          </div>
        </div>
        <div class="col-lg-5 col-sm-10 center">
          <div class="text-start">
            <h3>{{ $t("520x.title3") }}</h3>
            <ul>
              <li class="p" v-for="index in 6" :key="index">
                {{ $t(`520x.description3-${index + 1}`) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- OBSERV手冊 -->
    <!--
    <div class="position-relative text-on-img py-5">
      <img
        class="position-absolute w-100 h-100"
        src="@/assets/images/product/520x/520x_04.jpg"
      />
      <div class="container">
        <div class="row justify-content-center">
          <div class="offset-lg-7 col-lg-4 col-sm-10 p-5 text-start bg-opacity-white">
            <h3 class="text-black">{{ $t("520x.title4") }}</h3>
            <h5>{{ $t("520x.description4") }}</h5>
            <a
            class="hvr-icon-wobble-horizontal"
            href="../OBSERV_Brochure/OBSERV_Brochure.html"
            >
              <img class="w-100" src="@/assets/images/product/520x/520x_04_book.png" />
            </a>
          </div>
        </div>
      </div>
    </div> 
    -->
    <!-- OBSERV肌膚履歷 -->
    <div class="mt-5 position-relative text-on-img py-5">
      <img
        class="position-absolute w-100 h-100"
        src="@/assets/images/product/520x/520x_05.jpg"
      />
      <div class="container">
        <div class="row justify-content-center">
          <div class="offset-lg-7 col-lg-4 col-sm-10 p-5 text-start bg-opacity-white">
            <h3 class="text-black">{{ $t("520x.title5") }}</h3>
            <h5>{{ $t("520x.description5") }}</h5>
            <a
            class="hvr-icon-wobble-horizontal"
            href="../OBSERV_News/OBSERV_News.html"
            >
              <img class="w-100" src="@/assets/images/product/520x/520x_05_book.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 規格 -->
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-sm-10">
          <table class="table table-primary table-striped">
            <tbody>
              <tr v-for="index in 9" :key="index">
                <td class="td-1">{{ $t(`520x.spec${index}`) }}</td>
                <td class="td-2 text-start" v-html="$t(`520x.specV${index}`)"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-5 col-sm-10 center">
          <img class="w-100" src="@/assets/images/product/520x/520x_06.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeLM: "daylight",
      indexCN: 0,
      lights: [
        "daylight",
        "cross",
        "parrallel",
        "uv",
        "woods",
        "pigment",
        "redness",
        "texture",
        "dynamic",
      ],
    };
  },
  methods: {
    toggleLM(lm) {
      this.activeLM = lm;
      this.indexCN = 0;
    },
    toggleCN(i) {
      this.indexCN = i;
    },
  },
};
</script>
<style lang="scss">
.text-on-img {
  overflow: hidden;
  .position-absolute {
    top: 0;
    left: 0;
    z-index: -1;
    object-position: center bottom;
    object-fit: cover;
  }
}
#intro {
  a {
    text-decoration: none;
  }
}
.ipad {
  scale: 1.2;
  max-width: 80%;
}
.lm:hover {
  color: var(--bs-primary);
}
.lm.active {
  color: var(--bs-primary);
}
.concern.active {
  background: linear-gradient(
    to right,
    var(--bs-gradient0),
    var(--bs-gradient1),
    transparent
  );
  // color: white;
}
#title2 {
  top: 0;
}
.bg-opacity-white {
  background-color: rgba($color: white, $alpha: 0.5);
}
.td-1 {
  border-right: 3px solid white;
}
</style>
